import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ReCaptchaBadge, RecaptchaComponent, RecaptchaLoaderService } from '@rogers/ng-recaptcha';
import { delay, filter, map, take } from 'rxjs/operators';
import { ConfigurationService } from '../shared/configuration.service';
import { CookieService } from 'ngx-cookie-service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { LoadRecaptchaSitekeyService } from '../shared/load-recaptcha-site-key-service';
import { UserSessionRiskService } from '../shared/user-session-risk-service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisteFormSessionComponent implements OnInit, AfterViewInit {

  @ViewChild('submitCreate') submitCreateButton: ElementRef;
  @ViewChild('emailCreate') emailCreateField: ElementRef;

  onSubmitUserEmail: (payload: object) => void;
  onRegisterskipClick: () => void;
  onSignInClick: () => void;

  emailValidationError = false;
  errorCode = '';
  registerInProgress = false;
  invalidEmail = false;
  errorMesageCode = '';
  currentLang: string;
  // reCaptcha
  recaptcha: string;
  recaptchaBadge: ReCaptchaBadge = 'bottomright';
  recaptchaClass = 'recaptcha-non-ie';
  reCaptchaSiteKey: string;
  reCaptchaV3SiteKey: string;
  useRecaptchaV3 = true;
  blockRecaptcha = false;
  loginStart = false;
  recaptchaTriggered = false;
  reCaptchaVersion = 'V3';
  registerForm: UntypedFormGroup;

  @ViewChild('captchaRef') captchaRef: RecaptchaComponent;
  @ViewChild('captchaRefV3') captchaRefV3: RecaptchaComponent;

  constructor(
    private readonly recaptchaLoaderService: RecaptchaLoaderService,
    private readonly loadRecaptchaSiteKey: LoadRecaptchaSitekeyService,
    private readonly configurationService: ConfigurationService,
    private readonly cookieService: CookieService,
    private readonly translate: TranslateService,
    private readonly titleService: Title,
    private readonly userSessionRiskService: UserSessionRiskService)
  {
    let client = this.cookieService.get('client');
    this.reCaptchaSiteKey = this.loadRecaptchaSiteKey.getKey('V2',client);
    this.reCaptchaV3SiteKey = this.loadRecaptchaSiteKey.getKey('V3',client);
    // this.useRecaptchaV3 = this.configurationService.useRecaptchaV3;
  }

  ngOnInit(): void {

    const useRecaptcha = this.cookieService.get('registerUseRecaptchaV3');
    const version = this.cookieService.get('registerReCaptchaVersion');

    this.useRecaptchaV3 = useRecaptcha === 'false' ? false : true;
    this.reCaptchaVersion = version ? version : this.reCaptchaVersion;

    this.registerForm = new UntypedFormGroup(
      {
        email: new UntypedFormControl('' , [
          Validators.required,
          Validators.email
        ])
      },
      { updateOn: 'submit' }
    );
    const code = this.cookieService.get('registerErrorCode');

    if (!this.useRecaptchaV3 && this.reCaptchaVersion === 'V2'){
      const username = this.cookieService.get('registerUsername');
      this.registerForm.controls['email'].setValue(username);
    }

    if (code != null){
      if (code === 'UE01'){
        this.errorMesageCode = 'rogers.errors.' + code
        + 'rogers.errors.UE011' + 'rogers.errors.UE012';
      }
      else if (code === 'ECP3'){
        this.errorMesageCode = 'rogers.errors.' + code;
      }
      else{
        this.errorMesageCode = 'common.error.system';
      }

      this.errorCode = code;
    }
    this.currentLang = this.cookieService.get('lang');
    console.log('currentLang: ', this.currentLang);

    let brand = this.cookieService.get('brand');
    const client = this.cookieService.get('client');
    if (client === 'media'){
      brand = client;
    }

    this.titleService.setTitle(
      this.translate.instant(brand + '.registration.browserTitle')
    );
    console.log((brand + '.registration.browserTitle' + this.cookieService.get('register')));

    this.translate.onLangChange.subscribe((langEvent: LangChangeEvent) => {
    this.titleService.setTitle(
      this.translate.instant((brand + '.registration.browserTitle'))
    );
    this.currentLang = langEvent.lang;
  });
}

  ngAfterViewInit(): void {
    this.emailCreateField.nativeElement.focus();
  }

  validateAndSubmit() {
    this.resetErrorCode();
    if (this.registerForm.invalid && this.useRecaptchaV3) {
      this.registerInProgress = false;
      const error =  this.registerForm.get('email').errors;
      if (error && error.email){
        this.invalidEmail = true;
      }
      return;
    }
    else{
      this.invalidEmail = false;
      this.errorMesageCode = '';

      if (this.blockRecaptcha) {
          this.postSubmit();
      }
      else {
        this.triggerRecaptcha();
      }
    }
  }

  private postSubmit() {
    const userEmail = this.registerForm.value.email;

    if (this.recaptcha && userEmail !== null) {
    if (!this.registerForm.invalid) {

      this.triggerRiskAction('registration', userEmail);
      this.registerInProgress = true;

      this.onSubmitUserEmail({
        action: 'submit',
        username: userEmail,
        recaptachaToken: this.recaptcha,
        siteKey: this.reCaptchaVersion === 'V2' ? this.reCaptchaSiteKey : this.reCaptchaV3SiteKey,
        language: this.cookieService.get('lang')
      });
    } else {
      console.log('register email form is invalid');
      return;
     }
    }
    else {
      this.validateAndSubmit();
    }
  }

  keyDownEnter(event: any) {
    event.preventDefault();
    this.submitCreateButton.nativeElement.click();
  }

  resetErrorCode(){
    this.errorCode = '';
    this.invalidEmail = false;
    this.errorMesageCode = '';
    this.cookieService.delete('registerErrorCode');
  }

  private triggerRecaptcha() {

    this.recaptchaLoaderService.ready
      .pipe(
        filter(val => val !== null),
        take(1),
        map(val => !!val),
        delay(350)
      )
      .subscribe(recaptchaIsReady => {
        if (recaptchaIsReady) {
          if (!this.registerInProgress) {
            this.recaptchaTriggered = true;
            if (this.useRecaptchaV3 && !!this.captchaRefV3) {
              this.captchaRefV3.execute();
              this.reCaptchaVersion = 'V3';
            } else if (this.captchaRef) {
              this.captchaRef.execute();
              this.reCaptchaVersion = 'V2';
            }
          } else if (
            this.registerInProgress &&
            this.recaptchaTriggered &&
            !!this.captchaRef
          ) {
            this.captchaRef.execute();
            this.reCaptchaVersion = 'V2';
          }
        }
      });
  }

  resetRecaptcha() {
    if (!this.blockRecaptcha) {
      this.recaptcha = '';
      if (this.useRecaptchaV3 && this.captchaRefV3) {
        this.captchaRefV3.reset();
      } else if (!this.useRecaptchaV3 && this.captchaRef) {
        this.captchaRef.reset();
      }
    }
  }

  outsideClickCapture() {
    this.loginStart = false;
  }

  onRegisterskip(event){
    event.preventDefault();
    this.onRegisterskipClick();
  }

  signInClick(){
    this.onSignInClick();
  }

  // recaptcha section
  processRecaptcha(recaptcha: string) {

    this.cookieService.delete('registerUseRecaptchaV3');
    this.cookieService.delete('registerReCaptchaVersion');
    this.cookieService.delete('registerUsername');

    this.recaptcha = recaptcha;
    this.recaptchaTriggered = false;

    this.postSubmit();
  }

  async triggerRiskAction(flow: string, userId: string) {
    await this.userSessionRiskService.triggerAction(flow, userId);
  }

  getPolicyLink(): string {
    return 'https://www.rogers.com/support/privacy';
  }

  getSportsnetTermsLink(): string {
    const currentLang = this.translate.currentLang;
    if (currentLang === 'fr') {
      return 'https://secure.sportsnetplus.ca/terms-of-service?page_lang=fr';
    } else {
      return 'https://secure.sportsnetplus.ca/terms-of-service';
    }
  }

  getSeekrTermsLink(): string {
    const currentLang = this.translate.currentLang;
    if (currentLang === 'fr') {
      return 'https://secure.sportsnetplus.ca/terms-of-service?page_lang=fr';
    } else {
      return 'https://secure.sportsnetplus.ca/terms-of-service';
    }
  }

}
