
<ng-container *ngIf="passwordStore.flowBranch === flowBranchEnum.login">
  <ng-container *ngIf="trackPageView && !passwordStore.errorCode" ssTrackEvent [event]="track.loginPasswordInputPageView">
  </ng-container>
  <h1 class="text-title-4 mb-8" translate>{{signInHeader}}</h1>
  <p class="mb-24 text-body-lg" translate>{{headerHolder}}</p>
<p *ngIf='client !== "r4b"' class="text-body mb-2 text-bold">  {{ username }}</p>
<p *ngIf='client == "r4b"' class="text-body mb-24 text-bold">  {{ username }}</p>

  <a *ngIf='showChangeUsername'
  ds-link
  variant="cta"
  class="mb-24 ds-color-link"
  attr.title="{{ changeUsernameAttr | translate }}"
  (click)="routeLink('changeUsername')">
  {{ changeUsernameTitle | translate }}</a>

  <div class="mb-16" *ngIf="passwordStore.errorCode">
    <ds-alert
      variant="error"
      [closeLabel]="'rogers.login.popOverClose' | translate"
      (closeClick)="resetErrorMessage()"
      role="alert"
      ssTrackError
      [error]="track.loginPasswordApiErrors">
      <div class="d-flex flex-column flex-md-row">
        <div class="flex-grow-1 d-flex">
          <ds-icon
            class="mr-8"
            name="error-outlined"
            color="error"
            [ariaHidden]="false"
            [attr.title]="'rogers.login.accessibility.error' | translate"
            caption="Attention">
          </ds-icon>
          <div>
            <p *ngIf='passwordStore.errorCode === "UE03"; else otherErrors'
              damTranslationWithRouting
              class="text-body mb-0 mw-content">
              <span *ngIf ="client !== 'ignitetv'">
              {{ 'rogers.errors.' + passwordStore.errorCode | translate }}
 
              <a *ngIf='errCode' href="javascript:void(0)"
              variant="cta"
              class="mb-0 ds-color-link"
              [attr.title]="'common.accessibility.forgotPassword' | translate"
              (click)="onForgotPasswordClick()">
              {{ 'rogers.errors.forgotPassword' | translate }}</a>

              {{ errCode | translate }}
            </span>
            <span *ngIf ="client === 'ignitetv'">
              {{ 'rogersShaw.errors.password' | translate }}
 
              <a *ngIf='errCode' href="javascript:void(0)"
              class="mb-0 ds-color-link forgot-pwd-link"
              [attr.title]="'common.accessibility.forgotPassword' | translate"
              (click)="onForgotPasswordClick()">
              {{ 'rogersShaw.errors.forgotPassword' | translate }}</a>
              <span class="forgot-pwd">.</span>
              <span>
                {{ (igniteUserSelectedBrand === 'shaw') ?
                ('rogersShaw.rogers.errors.shawTv' | translate) 
                :('rogersShaw.shaw.errors.rogersTv' | translate)  }}
                {{'rogersShaw.errors.signInHere' | translate }}
              </span>
     
            </span>
            </p>
            <ng-template #otherErrors>
              <p
                damTranslationWithRouting
                class="text-body mb-0 mw-content">
                {{ 'rogers.errors.' + passwordStore.errorCode | translate }}
              </p>
            </ng-template>
          </div>
        </div>
      </div>
    </ds-alert>
  </div>

<!-- Password Form  -->
<form
  [formGroup]="loginForm"
  (ngSubmit)="validateAndSubmit()"
  #loginFormRef
  id="LoginForm"
  method="POST"
  novalidate
>

  <div class="hidden-username">
    <ds-form-field>
      <input
        dsInput
        type="email"
        formControlName="username"
      />
    </ds-form-field>
  </div>


  <div>
    <ds-form-field>
      <ds-input-label>{{ 'common.password' | translate }}</ds-input-label>
      <input
        [showPasswordToggleButton]="false"
        [attr.type]="showNewPassword ? 'password' : 'text'"
        type="password"
        dsInput
        [(ngModel)]="code"
        #passwordInput
        id="input_password"
        class="round-input"
        formControlName="input_password"
        name="input_password"
        autocapitalize="off"
        spellcheck="false"
        [attr.autocorrect]="'off'"
        autocomplete="current-password"
        (keydown.enter)="keyDownEnter($event)"
        (keydown.space)="$event.preventDefault()"
        autofill
        [attr.title]="
            'common.passwordAttr' | translate
          "
        [attr.aria-required]="true"
        [attr.aria-invalid]="false"
        [attr.aria-label]="
            'rogers.login.accessibility.showPassword' | translate
          "
      />
      <button
        dsSuffix
        ds-button
        type="button"
        variant="icon"
        [attr.aria-pressed]="!showNewPassword"
        (click)="showNewPassword = !showNewPassword"
        title="{{
            showNewPassword
              ? ('common.showPasword' | translate)
              : ('common.hidePassword' | translate)
          }}"
      >
        <ds-icon [name]="showNewPassword ? 'hide' : 'show'"></ds-icon>
      </button>
      <ds-error
        errorMessage="{{ 'rogers.errors.ECA3' | translate }}"
        alt="Attention"
        role="alert"
        *ngIf="passwordFormError"
        ssTrackError
        [error]="track.loginPasswordInputFormError"
      ></ds-error>
    </ds-form-field>
  </div>
  <div *ngIf="!accountLocked" style="margin-top: 5px;">
    <p class="mt-16 mb-16" *ngIf='(client === "ignitetv" && igniteUserSelectedBrand === "shaw") ; else registerLink'>
    </p>
    <ng-template #registerLink>
      <a
      ds-link
      variant="cta"
      class="mb-16 ds-color-link"
      [attr.title]="'common.accessibility.forgotPassword' | translate"
      (click)="onForgotPasswordClick()">
      {{ 'common.forgotPassword' | translate }}</a>
    </ng-template>


  </div>

  <div *ngIf="accountLocked"  class="mt-5 mb-16"></div>

  <div class="text-center signInButton ">
    <button
      ds-button
      role="button"
      class="w-100 mb-16"
      type="submit"
      #loginBtn
      title="{{ signinAttr | translate }}"
    >
        <span *ngIf="!loginInProgress">{{
          'rogers.login.signin' | translate
          }}</span>
          <eas-loader class="eas-loader" *ngIf="loginInProgress" ssTrackEvent [event]="track.loginSigninAttempt"></eas-loader>
    </button>
  </div>
</form>
</ng-container>


